import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight5: '#FFFFFF',
      neutralLight4: '#F7F7F7',
      neutralDark3: '#343638',
      neutralDark2: '#1F2021',
      neutralDark1: '#000000',
      primaryDark: '#0B0B0B',
      primaryLight: '#EBBD1D',
      dangerDark: '#E07355',
      danger: '#DB3E38',
    },
  },
  fontFamily: {
    heading: "'Bebas Neue', cursive",
    paragraph: "'Inter', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
